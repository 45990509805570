import React from 'react';
import { Col, Row } from "react-bootstrap";
import Countdown from "react-countdown";
import styled from 'styled-components';

// Styled component for the countdown timer
const CountdownStyled = styled.div`
  font-family: lumispeak;
  padding: 20px; // Add some padding around the text
  display: inline-block; // To ensure the border wraps around the content
  text-align: center; // Center align the countdown timer text
`;

// Styled component for centering content
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full height of the viewport
  flex-direction: column; // Stack children vertically
`;

export default function Construction() {
    return (
        <CenteredDiv>
            <Row>
                <Col>
                    <Row>
                        <img src={"/Lumis/Coming Soon.png"} style={{ width: "30%", margin: "auto" }} />
                    </Row>
                    <Row>
                    </Row>
                </Col>
            </Row>
        </CenteredDiv>
    );
}
