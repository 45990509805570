import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { fabric } from 'fabric';
import { Modal, Button } from 'react-bootstrap';
import { FaFileAlt, FaSave } from 'react-icons/fa';
import PSCanvas from '../components/PSCanvas';
import PropertiesPanel from '../components/PropertiesPanel';
import Toolbar from '../components/Toolbar';

const Studio = () => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeTool, setActiveTool] = useState(null); // 'text', 'paint', 'shape', 'select'
  const [brushSize, setBrushSize] = useState(5);
  const [brushColor, setBrushColor] = useState('#000000');
  const [fileName, setFileName] = useState('Untitled');
  const [showModal, setShowModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [clipboard, setClipboard] = useState(null);
  const [shapeType, setShapeType] = useState('rectangle');
  const [shapeOpacity, setShapeOpacity] = useState(1);
  const [shapeColor, setShapeColor] = useState('#000000');
  const [fileFormat, setFileFormat] = useState('png');
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [isEditingPanel, setIsEditingPanel] = useState(false); // New state

  const handleKeyDown = useCallback((e) => {
    const canvas = window.fabricCanvas;
    if (!canvas || (selectedElement && selectedElement.isEditing) || isEditingPanel) return; // Add isEditingPanel check

    if (e.ctrlKey) {
      switch (e.key.toLowerCase()) {
        case 'c':
          e.preventDefault();
          copyElement();
          break;
        case 'x':
          e.preventDefault();
          cutElement();
          break;
        case 'v':
          e.preventDefault();
          pasteElement();
          break;
        case 'z':
          e.preventDefault();
          undo();
          break;
        case 'y':
          e.preventDefault();
          redo();
          break;
        case 's':
          e.preventDefault();
          setShowSaveModal(true);
          break;
        case 'arrowup':
          e.preventDefault();
          switchTool();
          break;
        case 'd':
          e.preventDefault();
          duplicateElement();
          break;
        case 't':
          e.preventDefault();
          addText();
          break;
        default:
          break;
      }
    } else {
      switch (e.key.toLowerCase()) {
        case 'v':
          e.preventDefault();
          selectTool();
          break;
        case 'enter':
          e.preventDefault();
          deselectAllTools();
          break;
        case 'delete':
          e.preventDefault();
          deleteElement();
          break;
        default:
          break;
      }
    }
  }, [clipboard, selectedElement, activeTool, isEditingPanel]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const copyElement = () => {
    const canvas = window.fabricCanvas;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().clone((cloned) => {
        setClipboard(cloned);
      });
    }
  };

  const cutElement = () => {
    const canvas = window.fabricCanvas;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().clone((cloned) => {
        setClipboard(cloned);
        canvas.remove(canvas.getActiveObject());
      });
    }
  };

  const pasteElement = () => {
    const canvas = window.fabricCanvas;
    if (clipboard) {
      clipboard.clone((clonedObj) => {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === 'activeSelection') {
          clonedObj.canvas = canvas;
          clonedObj.forEachObject((obj) => {
            canvas.add(obj);
          });
          clonedObj.setCoords();
        } else {
          canvas.add(clonedObj);
        }
        setClipboard(clonedObj);
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
        saveState();
      });
    }
  };

  const duplicateElement = () => {
    const canvas = window.fabricCanvas;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().clone((cloned) => {
        canvas.discardActiveObject();
        cloned.set({
          left: cloned.left + 10,
          top: cloned.top + 10,
          evented: true,
        });
        if (cloned.type === 'activeSelection') {
          cloned.canvas = canvas;
          cloned.forEachObject((obj) => {
            canvas.add(obj);
          });
          cloned.setCoords();
        } else {
          canvas.add(cloned);
        }
        canvas.setActiveObject(cloned);
        canvas.requestRenderAll();
        saveState();
      });
    }
  };

  const deleteElement = () => {
    const canvas = window.fabricCanvas;
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === 'activeSelection') {
        activeObject.forEachObject((obj) => {
          canvas.remove(obj);
        });
      } else {
        canvas.remove(activeObject);
      }
      canvas.discardActiveObject();
      canvas.renderAll();
      saveState();
    }
  };

  const undo = () => {
    const canvas = window.fabricCanvas;
    if (undoStack.length > 0) {
      const previousState = undoStack.pop();
      setRedoStack([...redoStack, canvas.toJSON()]);
      canvas.loadFromJSON(previousState, () => {
        canvas.renderAll();
        setSelectedElement(null);
      });
    }
  };

  const redo = () => {
    const canvas = window.fabricCanvas;
    if (redoStack.length > 0) {
      const nextState = redoStack.pop();
      setUndoStack([...undoStack, canvas.toJSON()]);
      canvas.loadFromJSON(nextState, () => {
        canvas.renderAll();
        setSelectedElement(null);
      });
    }
  };

  const saveState = () => {
    const canvas = window.fabricCanvas;
    setUndoStack([...undoStack, canvas.toJSON()]);
    setRedoStack([]);
  };

  const createNewProject = () => {
    setShowModal(true);
  };

  const confirmNewProject = () => {
    const canvas = window.fabricCanvas;
    canvas.clear();
    canvas.setBackgroundColor('black', canvas.renderAll.bind(canvas));
    setSelectedElement(null);
    setActiveTool(null);
    setFileName('Untitled');
    setShowModal(false);
    setUndoStack([]);
    setRedoStack([]);
  };

  const saveProjectAsPNG = () => {
    const canvas = window.fabricCanvas;
    const dataURL = canvas.toDataURL({
      format: fileFormat,
      quality: 1.0,
    });
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `${fileName}.${fileFormat}`;
    link.click();
    setShowSaveModal(false);
  };

  const changeBackgroundColor = (color) => {
    const canvas = window.fabricCanvas;
    canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas));
    saveState();
  };

  const startPainting = () => {
    const canvas = window.fabricCanvas;
    if (activeTool === 'paint') {
      setActiveTool(null);
      canvas.isDrawingMode = false;
    } else {
      setActiveTool('paint');
      canvas.isDrawingMode = true;
      canvas.freeDrawingBrush.width = brushSize;
      canvas.freeDrawingBrush.color = brushColor;
      saveState();
    }
  };

  const changeBrushSize = (size) => {
    setBrushSize(size);
    if (activeTool === 'paint') {
      const canvas = window.fabricCanvas;
      canvas.freeDrawingBrush.width = size;
    }
  };

  const changeBrushColor = (color) => {
    setBrushColor(color);
    if (activeTool === 'paint') {
      const canvas = window.fabricCanvas;
      canvas.freeDrawingBrush.color = color;
    }
  };

  const changeCanvasSize = (ratio) => {
    const canvas = window.fabricCanvas;
    let width, height;
    if (ratio === '1x1') {
      width = 500;
      height = 500;
    } else if (ratio === '2x3') {
      width = 400;
      height = 600;
    } else if (ratio === '1.25x1.5') {
      width = 500;
      height = 600;
    }
    canvas.setDimensions({ width, height });
    canvas.renderAll();
    saveState();
  };

  const addText = () => {
    setActiveTool('text');
    const canvas = window.fabricCanvas;
    const text = new fabric.IText('Hello World', {
      left: 100,
      top: 100,
      fill: '#ffffff',
      fontSize: 20,
      stroke: '#000000',
      strokeWidth: 0, // Set default stroke width to zero
      strokeDirection: 'center', // Default stroke direction
      strokeOffset: 0, // Default stroke offset
    });
    canvas.add(text);
    canvas.setActiveObject(text);
    canvas.renderAll();
    setSelectedElement(text);
    saveState();
  };

  const startAddingShape = () => {
    setActiveTool('shape');
    addShape('rectangle');
  };

  const addShape = (type) => {
    const canvas = window.fabricCanvas;
    let shape;
    switch (type) {
      case 'triangle':
        shape = new fabric.Triangle({ width: 50, height: 50, fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
      case 'circle':
        shape = new fabric.Circle({ radius: 25, fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
      case 'hexagon':
        shape = new fabric.Polygon([
          { x: 25, y: 0 },
          { x: 50, y: 15 },
          { x: 50, y: 45 },
          { x: 25, y: 60 },
          { x: 0, y: 45 },
          { x: 0, y: 15 }
        ], { fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
      default:
        shape = new fabric.Rect({ width: 50, height: 50, fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
    }
    canvas.add(shape);
    canvas.setActiveObject(shape);
    canvas.renderAll();
    setSelectedElement(shape);
    saveState();
  };

  const selectTool = () => {
    setActiveTool(null);
    const canvas = window.fabricCanvas;
    canvas.isDrawingMode = false;
  };

  const changeTextColor = (color) => {
    if (selectedElement && selectedElement.type === 'i-text') {
      selectedElement.set({ fill: color });
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const changeFontSize = (size) => {
    if (selectedElement && selectedElement.type === 'i-text') {
      selectedElement.set({ fontSize: size });
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const changeFontFamily = (font) => {
    if (selectedElement && selectedElement.type === 'i-text') {
      selectedElement.set({ fontFamily: font });
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const changeStrokeSize = (size) => {
    if (selectedElement && selectedElement.type === 'i-text') {
      selectedElement.set({ strokeWidth: size / 10 });
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const changeStrokeColor = (color) => {
    if (selectedElement && selectedElement.type === 'i-text') {
      selectedElement.set({ stroke: color });
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const changeStrokeDirection = (direction) => {
    if (selectedElement && selectedElement.type === 'i-text') {
      selectedElement.strokeDirection = direction; // Save the direction on the element
      updateStroke();
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const changeStrokeOffset = (offset) => {
    if (selectedElement && selectedElement.type === 'i-text') {
      selectedElement.strokeOffset = offset; // Save the offset on the element
      updateStroke();
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const updateStroke = () => {
    if (selectedElement && selectedElement.type === 'i-text') {
      const strokeWidth = selectedElement.strokeWidth || 0;
      const offset = selectedElement.strokeOffset || 0;
      const direction = selectedElement.strokeDirection || 'center';

      let stroke = selectedElement.stroke || '#000000';
      let fill = selectedElement.fill || '#ffffff';

      // Create a clone to manipulate for stroke effects
      const clone = selectedElement.clone();
      clone.set({
        strokeWidth: strokeWidth,
        stroke: stroke,
        fill: 'transparent',
      });

      // Remove existing stroke
      selectedElement.set({
        strokeWidth: 0,
      });

      // Apply the stroke direction effect
      if (direction === 'inner') {
        clone.set({
          left: selectedElement.left + offset,
          top: selectedElement.top + offset,
        });
      } else if (direction === 'outer') {
        clone.set({
          left: selectedElement.left - offset,
          top: selectedElement.top - offset,
        });
      } else {
        clone.set({
          left: selectedElement.left,
          top: selectedElement.top,
        });
      }

      // Add clone to the canvas
      window.fabricCanvas.add(clone);
      window.fabricCanvas.bringToFront(selectedElement);
      window.fabricCanvas.renderAll();
    }
  };

  const changeShapeType = (type) => {
    setShapeType(type);
    if (activeTool === 'shape' && selectedElement) {
      const canvas = window.fabricCanvas;
      canvas.remove(selectedElement);
      addShape(type);
      saveState();
    }
  };

  const changeShapeOpacity = (opacity) => {
    setShapeOpacity(opacity);
    if (selectedElement && activeTool === 'shape') {
      selectedElement.set({ opacity });
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const changeShapeColor = (color) => {
    setShapeColor(color);
    if (selectedElement && activeTool === 'shape') {
      selectedElement.set({ fill: color });
      window.fabricCanvas.renderAll();
      saveState();
    }
  };

  const deselectAllTools = () => {
    setActiveTool(null);
    const canvas = window.fabricCanvas;
    canvas.discardActiveObject();
    canvas.renderAll();
  };

  const switchTool = () => {
    if (activeTool === 'text') {
      setActiveTool('paint');
      startPainting();
    } else if (activeTool === 'paint') {
      setActiveTool('text');
      addText();
    }
  };

  return (
    <Container>
      <Sidebar>
        <h2>Tools</h2>
        <Toolbar
          addTextToCanvas={addText}
          startPainting={startPainting}
          startAddingShape={startAddingShape}
          activeTool={activeTool}
        />
      </Sidebar>
      <MainContent>
        <CanvasArea>
          <PSCanvas
            setSelectedElement={setSelectedElement}
            activeTool={activeTool}
            shapeType={shapeType}
            shapeOpacity={shapeOpacity}
            shapeColor={shapeColor}
          />
        </CanvasArea>
      </MainContent>
      <PropertiesPanel
        propertiesType={activeTool}
        selectedElement={selectedElement}
        changeTextColor={changeTextColor}
        changeFontSize={changeFontSize}
        changeFontFamily={changeFontFamily} // Pass the function to PropertiesPanel
        changeStrokeSize={changeStrokeSize}
        changeStrokeColor={changeStrokeColor}
        changeStrokeDirection={changeStrokeDirection}
        changeStrokeOffset={changeStrokeOffset}
        changeBackgroundColor={changeBackgroundColor}
        changeBrushSize={changeBrushSize}
        changeBrushColor={changeBrushColor}
        brushSize={brushSize}
        brushColor={brushColor}
        changeCanvasSize={changeCanvasSize}
        fileName={fileName}
        setFileName={setFileName}
        changeShapeType={changeShapeType}
        changeShapeOpacity={changeShapeOpacity}
        changeShapeColor={changeShapeColor}
        shapeType={shapeType}
        shapeOpacity={shapeOpacity}
        shapeColor={shapeColor}
        createNewProject={createNewProject}
        saveProjectAsPNG={() => setShowSaveModal(true)}
        setIsEditingPanel={setIsEditingPanel} // New
      />
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton style={{ backgroundColor: '#333', borderBottom: '1px solid #444' }}>
          <Modal.Title style={{ color: '#fff' }}>Create New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#333', color: '#fff' }}>
          If you create a new project, all unsaved data will be lost. Do you want to proceed?
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#333', borderTop: '1px solid #444' }}>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmNewProject}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)} centered>
        <Modal.Header closeButton style={{ backgroundColor: '#333', borderBottom: '1px solid #444' }}>
          <Modal.Title style={{ color: '#fff' }}>Save Project</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#333', color: '#fff' }}>
          <Label>File Name:</Label>
          <input
            type="text"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
          />
          <Label>File Format:</Label>
          <select
            value={fileFormat}
            onChange={(e) => setFileFormat(e.target.value)}
            style={{ width: '100%', padding: '5px' }}
          >
            <option value="png">PNG</option>
            <option value="jpg">JPG</option>
            <option value="pdf">PDF</option>
          </select>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#333', borderTop: '1px solid #444' }}>
          <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveProjectAsPNG}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Studio;

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: #121212;
  color: #ffffff;
`;

const Sidebar = styled.div`
  background: #1e1e1e;
  width: 200px;
  padding: 10px;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ffffff;
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #121212;
  padding: 10px;
  box-sizing: border-box;
`;

const CanvasArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1e1e1e;
  padding: 25px;
  box-sizing: border-box;
`;

const Label = styled.label`
  margin: 10px 0 5px;
  font-size: 0.9em;
`;
