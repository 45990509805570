import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

// Styled components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
`;

const PopupContent = styled.div`
  background-color: #ffe0b2;
  padding: 10px;
  width: 75%;
  border-radius: 10px;
  text-align: center;
  font-family: lumispeak;
  zIndex: 5;
`;

const CloseButton = styled.button`
position: absolute;
  font-size: 5em;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  margin: 0%;
`;

// Popup component
const Popup = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => setIsVisible(false);

  return isVisible ? (
    <PopupOverlay>
      <PopupContent>
        <CloseButton onClick={handleClose}>X</CloseButton>
        <StyledImage src="/Promos/season_one.png" alt="Promotion" />
      </PopupContent>
    </PopupOverlay>
  ) : null;
};

export default Popup;
