import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: 25%;
  width: 60px;
  height: 200px;
  background-color: #FFBC3D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: 10;
`;


const LumiSidebar = () => {
  return (
    <Sidebar>
      <a href="https://twitter.com/lumiparadox"><Icon src="/Icons/twitter.png"/></a>
      <a href="https://discord.com/invite/HWNAYwARvj"><Icon src="/Icons/discord.png"/></a>
      <a href="https://opensea.io/lumiparadox"><Icon src="/Icons/os.png"/></a>
      <a href="https://blur.io/collection/lumiparadox"><Icon src="/Icons/blur.png"/></a>
    </Sidebar>
  );
};

export default LumiSidebar;