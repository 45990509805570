import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { SizedBox } from "./SizedBox";
import { MobileView, BrowserView } from "react-device-detect";

const LumiImg = styled.img`

`;
const Heading = styled.h1
`
margin: 30px;
font-family: lumispeak;
color: #572E2E;
`;

const SubTitle = styled.h2
`
margin: 30px;
font-family: lumispeak;
color: #572E2E;
`;

const SubText = styled.h4
`
margin-x: 10%;
`;

const LumiGrid = () => 
{
    return(
        <>
        <BrowserView>
        <div style={{textAlign: "center"}}>
            <Heading>Meet the Lumis</Heading>
            <SubText>Each Lumi has his own personality type that people can resonate with.</SubText>
            <SubText>Which Lumi will be yours?</SubText>
            <SizedBox height={"70px"} />
            <Col>
            <Row>
                <Col>
                <img src={"/Lumis/Headshots/Bandit.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    
                    <SubTitle>Bandit</SubTitle>
                    <SubText>I like to buy low and sell high. Sometimes it works, most times it doesn't</SubText>
                </Col>
                <Col>
                    <img src={"/Lumis/Headshots/Glitch.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Glitch</SubTitle>
                    <SubText>I was created by accident due to a wrong line in the code</SubText>
                </Col>
                <Col>
                    <img src={"/Lumis/Headshots/Graves.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Graves</SubTitle>
                    <SubText>Nobody knows my story or who I am, but I like to keep it that way</SubText>
                </Col>
            </Row>
            <SizedBox height={"100px"} />
            <Row>
                <Col>
                    <img src={"/Lumis/Headshots/Rogue.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Rogue</SubTitle>
                    <SubText>I literally do not give a [REDACTED], scared money don't make money and I ain't scared</SubText>
                </Col>
                <Col>
                    <img src={"/Lumis/Headshots/Yolo.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Yolo</SubTitle>
                    <SubText>Life is too short for bs and drama, I'm just chillin</SubText>
                </Col>
                <Col>
                    <img src={"/Lumis/Headshots/Ghost.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>1/1</SubTitle>
                    <SubText>There are 10 of us</SubText>
                </Col>
            </Row>
            </Col>
        </div>
        </BrowserView>
        <MobileView>
        <div style={{textAlign: "center", margin: "auto"}}>
            <Heading>Meet the Lumis</Heading>
            <SubText>Each Lumi has his own personality type that people can resonate with.</SubText>
            <SubText>Which Lumi will be yours?</SubText>
            <SizedBox height={"70px"} />
            <Col>
            <Row>
                <Col>
                <img src={"/Lumis/Headshots/Bandit.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    
                    <SubTitle>Bandit</SubTitle>
                    <SubText>I like to buy low and sell high. Sometimes it works, most times it doesn't</SubText>
                </Col>
                <Col>
                    <img src={"/Lumis/Headshots/Glitch.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Glitch</SubTitle>
                    <SubText>I was created by accident due to a wrong line in the code</SubText>
                </Col>
            </Row>
            <Row>
                <Col>
                    <img src={"/Lumis/Headshots/Graves.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Graves</SubTitle>
                    <SubText>Nobody knows my story or who I am, but I like to keep it that way</SubText>
                </Col>
                <Col>
                    <img src={"/Lumis/Headshots/Rogue.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Rogue</SubTitle>
                    <SubText>I literally do not give a [REDACTED], scared money don't make money and I ain't scared</SubText>
                </Col>
            </Row>
            <SizedBox height={"100px"} />
            <Row>
                
                <Col>
                    <img src={"/Lumis/Headshots/Yolo.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>Yolo</SubTitle>
                    <SubText>Life is too short for bs and drama, I'm just chillin</SubText>
                </Col>
                <Col>
                    <img src={"/Lumis/Headshots/Ghost.png"} style={{width: "70%", borderRadius: "10%"}}/>
                    <SubTitle>1/1</SubTitle>
                    <SubText>There are 10 of us</SubText>
                </Col>
            </Row>
            </Col>
        </div>
        </MobileView>
        </>
    )
}

export default LumiGrid;