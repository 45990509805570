import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile, BrowserView, MobileView } from "react-device-detect";
import styled from "styled-components";
import mergeImages from "merge-images";
import ImageTray from "../components/ImageTray";
import { readString } from "react-papaparse";
import csvFile from "../contracts/metadata.csv";
import abi from "../contracts/abi.json";
import { ethers } from "ethers";

const contractAddress = "0x15ec431c24e791574f5dd9e16f7f2cd5bfffbb27";

// Styled components definitions
const Title = styled.h1`
  font-size: 1.5em;
  font-family: lumispeak;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: isMobile ? 95% : 80%;
  margin: auto;
  padding: 25px;

  .left-container {
    width: 45%;

    border-radius: 15px; /* Rounded corners */
    overflow: hidden; /* Ensure rounded corners are applied */
  }

  .right-container {
    width: 45%;
    border-radius: 15px; /* Rounded corners */
    overflow: hidden; /* Ensure rounded corners are applied */
  }
`;

const StyledAccessoriesContainer = styled.div`
  margin-bottom: 25px;
  width: 100%; /* Adjusted width */
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Ensure rounded corners are applied */
`;

const StyledLumisContainer = styled.div`
  width: 100%; /* Adjusted width */
`;

const StyledMainImageContainer = styled.div`
  padding: 5px;

  width: 100%;
  margin: auto;
`;

const StyledImageContainer = styled.div`
border-radius: 10px; /* Rounded corners */

  padding: 20px 5px 5px 5px; /* Updated padding */
  width: 100%;
  margin: auto;
`;


const StyledButton = styled.button`
  padding: 5px;
  background-color: #572E2E;
  width: 100%;
  margin: auto;
  border-radius: 10px; /* Rounded corners */
`;

const StyledAccessoryContainer = styled.div`

  padding: 20px;
  background-color: #572E2E;
 
  width: 100%;
  margin: auto;
  z-index: -100;
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Ensure rounded corners are applied */
`;

const StyledImageWrapper = styled.div`
  padding-top: 20px; /* Adjust top padding as needed */
  position: relative;
`;


// Main component
export default function GMMachine() {
  // Define state variables
  const none = "/LumiBuilder/body trait/none.png";
  const [currentAccount, setCurrentAccount] = useState(null);
  const [nftList, setNftList] = useState(null);
  const [background, setBackground] = useState(null);
  const [base, setBase] = useState(null);
  const [expression, setExpression] = useState(null);
  const [shirt, setShirt] = useState(null);
  const [hat, setHat] = useState(null);
  const [glasses, setGlasses] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [item, setItem] = useState(none);
  const [hand, setHand] = useState(none);
  const [sleeve, setSleeve] = useState(none);
  const [image, setImage] = useState(null);
  const [partsTray, setPartsTray] = useState(<ImageTray folder="GMMachineImages" text={false} dir="items" onClick={(item) => {updateItem({item});}}/>);
  const [b64, setB64] = useState(null);
  const [currentChain, setCurrentChain] = useState(null);

  // useEffect hook to perform side effects
  useEffect(() => {
    checkWalletConnection();
    if (currentAccount) {
      getNFTs();
    }
  }, [currentAccount, currentChain]);

  useEffect(() => {
    console.log(background, base, expression, glasses, shirt, hat, item);
    if (base) {
      setHand(item == null ? null : "/GMMachineImages/arms/arm_" + base.split("/")[3]);
      setSleeve(item == null ? null : "/GMMachineImages/sleeves/sleeve_" + shirt.split("/")[3]);
      updateImage();
    }
    updateImage();
  }, [background, base, expression, glasses, shirt, hat, item, hand, sleeve]);

  useEffect(() => {
    fetch(csvFile)
      .then((response) => response.text())
      .then((csvString) => {
        const result = readString(csvString, {
          header: true,
          skipEmptyLines: true,
        });
        setCsvData(result.data);
      })
      .catch((error) => console.error("Error fetching or parsing CSV file:", error));
  }, []);

  // Function to check wallet connection
  const checkWalletConnection = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      console.log("You need to have Metamask installed!");
      return;
    } else {
      console.log("Metamask is installed.");
    }

    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length > 0) {
      const account = accounts[0];
      setCurrentAccount(account);
    }
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
    setCurrentChain("0x1");
  };

  // Function to connect wallet
  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("You need to have Metamask installed!");
    }

    try {
      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      setCurrentAccount(accounts[0]);
    } catch (e) {
      console.log(e);
    }
  };

  // Function to update selected item
  const updateItem = async ({ item }) => {
    setItem(item);
  };

  // Function to get NFTs
  // Function to get NFTs
const getNFTs = async () => {
    try {
      const { ethereum } = window;
      const provider = new ethers.providers.Web3Provider(ethereum);
      const nftcontract = new ethers.Contract(contractAddress, abi, provider);
      console.log(currentAccount);
      let ownedNfts = await nftcontract.tokensOfOwner(currentAccount);
      let newNftList = [];
  
      if (ownedNfts.length > 0) {
        // Display the first Lumi instantly
        const num = parseInt(ownedNfts[0]._hex, 16);
        getNFTParts(num);
      }
  
      ownedNfts.forEach((el) => {
        let num = parseInt(el._hex, 16);
        newNftList.push(
          <Col key={num}> {/* Add a unique key */}
            <img src={"https://ipfs.io/ipfs/bafybeiey5cnxodegksmgb3g66p2psbq7b2e7ihkh5iayfal7h6k3vog4vq/" + num + ".png"} style={{ cursor: "pointer", borderRadius: "10px" }} width={"100%"} onClick={() => getNFTParts(num)} />
          </Col>
        );
  
        let rows = [];
        let cols = [];
  
        for (let i = 0; i < newNftList.length; i++) {
          cols.push(newNftList[i]);
  
          if (cols.length === 5) {
            rows.push(<Row key={i}>{cols}</Row>);
            cols = [];
          }
        }
  
        for (let j = cols.length; j < 5; j++) {
          cols.push(<Col key={j}></Col>);
        }
  
        rows.push(<Row key={num}>{cols}</Row>);
        setNftList(<div>{rows}</div>);
      });
    } catch (e) {
      console.log(e);
    }
  };
  
  

  // Function to get NFT parts
  const getNFTParts = async (num) => {
    try {
      const nftData = csvData[num];
      if (nftData) {
        setBackground(nftData.background ? "/LumiBuilder/background/" + nftData.background + ".png" : null);
        setBase(nftData.base ? "/LumiBuilder/base/" + nftData.base + ".png" : null);
        setShirt(nftData['body trait'] ? "/LumiBuilder/body trait/" + nftData['body trait'] + ".png" : null);
        setExpression(nftData.expressions ? "/LumiBuilder/expressions/" + nftData.expressions + ".png" : null);
        setHat(nftData['top head'] ? "/LumiBuilder/top head/" + nftData['top head'] + ".png" : null);
        setGlasses(nftData.glasses ? "/LumiBuilder/glasses/" + nftData.glasses + ".png" : null);
      } else {
        console.log(`Row ${num} not found`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Function to update image
  const updateImage = async () => {
    const imageSources = [background, base, expression, glasses, shirt, hat, hand, item, sleeve].filter((src) => src != null);
    if (imageSources.length > 0) {
      let b64t = await mergeImages(imageSources);
      setB64(b64t);
      setImage(<img src={b64t} style={{ width: "100%", display: "block" }} alt="Lumi" />);
    }
  };

  return (
    <>
      <BrowserView>
        <StyledContainer>
          <div className="left-container">
            {/* Your choose Lumis and accessories components */}
            {/* For example: */}
            <StyledLumisContainer>
              <h3>Choose your Lumi:</h3>
              <StyledButton onClick={connectWalletHandler}>
                {currentAccount ? (
                  nftList ? (
                    <div>{nftList}</div>
                  ) : (
<p style={{ color: 'white' }}>Looks like there are no Lumis in this wallet! Try a different one or purchase a Lumi NFT to use this feature!</p>
                  )
                ) : (
                    <span style={{ color: 'white', fontFamily: 'lumispeak' }}>Connect Wallet</span>
                )}
              </StyledButton>
            </StyledLumisContainer>
            <StyledAccessoriesContainer>
            <h3 style={{ paddingTop: '15px' }}>Choose accessories:</h3>              {/* Your accessories container */}
              {/* For example: */}
              <StyledAccessoryContainer>
                {base ? (
                  <Col>
                    <Row>
                      <Col>{partsTray}</Col>
                    </Row>
                  </Col>
                ) : (
<h2 style={{ color: 'white', textAlign: 'center', }}>Choose a Lumi first!</h2>                )}
              </StyledAccessoryContainer>
            </StyledAccessoriesContainer>
          </div>
          <div className="right-container">
            {/* Your main image container */}
            <StyledMainImageContainer>
              <StyledImageContainer >
              <StyledImageWrapper>
              <div style={{ position: "relative", paddingTop: "75px" }}>
                  {/* Conditional rendering of the image */}
                  {image }
                  {/* Your click to download text */}
                  {b64 && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <h5>Click on the image to download it!</h5>
                    </div>
                  )}
                </div>
                </StyledImageWrapper>
              </StyledImageContainer>
            </StyledMainImageContainer>
          </div>
        </StyledContainer>
      </BrowserView>

      <MobileView>
        <StyledContainer>
          <div>
            {/* Your choose Lumis and accessories components */}
            {/* For example: */}
            <StyledLumisContainer>
              <h3>Choose your Lumi:</h3>
              <StyledButton onClick={connectWalletHandler}>
                {currentAccount ? (
                  nftList ? (
                    <div>{nftList}</div>
                  ) : (
                    <p style={{ color: 'white' }}>Looks like there are no Lumis in this wallet! Try a different one or purchase a Lumi NFT to use this feature!</p>
                  )
                ) : (
                    <span style={{ color: 'white', fontFamily: 'lumispeak' }}>Connect Wallet</span>
                )}
              </StyledButton>
            </StyledLumisContainer>
            <StyledMainImageContainer>
              <StyledImageContainer >
              <StyledImageWrapper>
              <div style={{ position: "relative" }}>
                  {/* Conditional rendering of the image */}
                  {image }
                  {/* Your click to download text */}
                  {b64 && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <p style={{fontFamily: "lumispeak"}}>Click on the image to download it!</p>
                    </div>
                  )}
                </div>
                </StyledImageWrapper>
              </StyledImageContainer>
            </StyledMainImageContainer>
            <StyledAccessoriesContainer>
            <h3>Choose accessories:</h3>              {/* Your accessories container */}
              {/* For example: */}
              <StyledAccessoryContainer>
                {base ? (
                  <Col>
                    <Row>
                      <Col>{partsTray}</Col>
                    </Row>
                  </Col>
                ) : (
<h2 style={{ color: 'white', textAlign: 'center', }}>Choose a Lumi first!</h2>                )}
              </StyledAccessoryContainer>
            </StyledAccessoriesContainer>
          </div>
          <div className="right-container">
            {/* Your main image container */}
            
          </div>
        </StyledContainer>
      </MobileView>
    </>
  );
}
