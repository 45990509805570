import React from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import styled from 'styled-components';

const StyledNavbar = styled.nav`
  background-color: #ffe0b2; /* Customize as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0; 
  left: 0%; 
  width: 100%; 
  z-index: 1000;
`;

const Logo = styled.img`
  height: 80px; /* Customize as needed */
  padding: 10px;
`;

const NavItem = styled.a`
  margin: 0 25px;
  cursor: pointer;
  color: #572E2E; /* Customize as needed */
  text-decoration: none;
`;

const DashboardButton = styled.a`
  background-color: #572E2E; /* Customize as needed */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 0 0 35px;
  border-radius: 50px;
  text-decoration: none;
  font-family: lumispeak; /* Set font family */
  &:hover {
    background-color: grey;
  }
`;

const DashboardNavbar = () => {
    return (
      <StyledNavbar>
        <Logo src={"/logos/navbarlogo.png"} alt="Logo" />
        <div>
          <BrowserView>
            <a href="/" style={{textDecoration: 'none'}}><DashboardButton>Go Back</DashboardButton></a>
          </BrowserView>
          <MobileView>
            <a href="/" style={{textDecoration: 'none'}}><DashboardButton href="/">Go back</DashboardButton></a>
          </MobileView>
        </div>
      </StyledNavbar>
    );
};

export default DashboardNavbar;
