import React from 'react';
import { Link } from 'react-router-dom';

const PSHome = () => (
  <div>
    <h1>Welcome to Adobe Clone</h1>
    <Link to="/photoshop">Go to Photoshop</Link>
  </div>
);

export default PSHome;
