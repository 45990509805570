import React from 'react';
import styled from 'styled-components';
import { SizedBox } from './SizedBox';
import { Row, Col } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';

// Define styled components

const ImageBlock = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Provide some spacing */
  margin-bottom: 10px; /* Space between image blocks if needed */
  border-radius: 25px; /* If you desire rounded corners for the image block itself */
`;

// Image component adjustment
const Image = styled.img`
  width: 100%; /* Make image fill the container width */
  height: auto; /* Maintain aspect ratio */
  max-height: 100%; /* Optional: Limit image height to fit the container */
  border-radius: 25px; /* Maintain rounded corners */
  object-fit: cover; /* Cover the area of the container, might crop the image */
`;

const Heading = styled.h1`
  margin: 15px;
  font-family: lumispeak;
  color: #572E2E;
`;

const SubTitle = styled.h2`
  font-family: lumispeak;
  color: #572E2E;
`;

const SubText = styled.h5`
  margin-x: 5%;
`;

// Image gallery component
const CommunityGrid = () => {
  return (
    <div style={{ textAlign: "center" }}>
    <BrowserView>
<Heading>A truly based community</Heading>
<SubText>Check out our community's creations!</SubText>
<SizedBox height={"70px"} />
    <Row>
        <Col>
            <Row>
                <ImageBlock href="https://twitter.com/DragonmasterEth">
                    <Image src="/Community/8.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock className="portrait">
                    <Image src="/Community/5.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/JohnDoe55573">
                    <Image src="/Community/2.png" alt="Portrait" />
                </ImageBlock>
            </Row>
        </Col>
        <Col>
            <Row>
                <ImageBlock href="https://twitter.com/Nikkii_eth">
                    <Image src="/Community/4.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/Recoverrr">
                    <Image src="/Community/7.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/Adamski_eth">
                    <Image src="/Community/10.png" alt="Portrait" />
                </ImageBlock>
            </Row>
        </Col>
        <Col>
            <Row>
                <ImageBlock href="https://twitter.com/JohnDoe55573">
                    <Image src="/Community/3.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/ednfts_eth">
                    <Image src="/Community/9.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/sschwingg">
                    <Image src="/Community/6.png" alt="Portrait" />
                </ImageBlock>
            </Row>
        </Col>
    </Row>
    </BrowserView>
    <MobileView>
    <Heading>A truly based community</Heading>
<SubText>Check out our community's creations!</SubText>
<SizedBox height={"70px"} />
    <Row>
        <Col>
            <Row>
                <ImageBlock href="https://twitter.com/DragonmasterETH">
                    <Image src="/Community/8.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/Nikkii_eth">
                    <Image src="/Community/4.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock  href="https://twitter.com/Adamski_eth">
                    <Image src="/Community/10.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/sschwingg">
                    <Image src="/Community/6.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            </Col>
        <Col>
            <Row>
                <ImageBlock href="https://twitter.com/Recoverrr">
                    <Image src="/Community/7.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/JohnDoe55573">
                    <Image src="/Community/2.png" alt="Portrait" />
                </ImageBlock>
            </Row>

            <Row>
                <ImageBlock href="https://twitter.com/JohnDoe55573">
                    <Image src="/Community/3.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock href="https://twitter.com/ednfts_eth">
                    <Image src="/Community/9.png" alt="Portrait" />
                </ImageBlock>
            </Row>
            <Row>
                <ImageBlock className="portrait">
                    <Image src="/Community/5.png" alt="Portrait" />
                </ImageBlock>
            </Row>
        </Col>
    </Row>
    </MobileView>
    </div>
  );
};

export default CommunityGrid;
