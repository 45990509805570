import React from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import styled from 'styled-components';

const StyledNavbar = styled.nav`
font-family: "lumispeak";
  background-color: #ffe0b2; /* Customize as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0; 
  left: 0%; 
  width: 100%; 
  z-index: 1000;
`;

const Logo = styled.img`
  height: 80px; /* Customize as needed */
  padding: 10px;
`;

const NavItem = styled.a`
font-family: "lumispeak";

  margin: 0 25px;
  cursor: pointer;
  color: #572E2E; /* Customize as needed */
    text-decoration: none;
`;

const DashboardButton = styled.button`
  background-color: #572E2E; /* Customize as needed */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 0 0 35px;
    border-radius: 50px;
  &:hover {
    background-color: grey;
  }
`;

const LumiNavbar = () => {
    return (
        <div style={{width: "100%", margin: "auto"}}>
      <StyledNavbar>
        <Logo src={"/logos/navbarlogo.png"} alt="Logo" />
        <div>
          <BrowserView>
          <NavItem href="#about">About</NavItem>
          <NavItem href="#lumis">Meet the Lumis</NavItem>
          <NavItem href="#psyop">Psyop</NavItem>
          <NavItem href="#community">Community</NavItem>
          <NavItem href="#faq">FAQ</NavItem>
          <a href="/dashboard"><DashboardButton>Dashboard</DashboardButton></a>
          </BrowserView>
          <MobileView>
          <a href="/dashboard"><DashboardButton href="/dashboard">Dashboard</DashboardButton></a>
          </MobileView>
        </div>
      </StyledNavbar>
      </div>
    );
  };

  export default LumiNavbar