import React from "react";
import styled from "styled-components";
import DashboardNavbar from "../components/Dashboard_Navbar";
import DashboardHero from "../components/Dashboard_Hero";
import Footer from "../components/Footer";

const DashboardContainer = styled.div`
  width: ${({ isMobile }) => (isMobile ? "90%" : "80%")};
  margin: auto;
`;

export default function Dashboard() {
  const isMobile = true; 

  return (
    <DashboardContainer isMobile={isMobile}>
      <DashboardNavbar />
      <div id="about">
        <DashboardHero />
      </div>
      <Footer />
    </DashboardContainer>
  );
}
