import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
  position: relative;
  background-color: #FFBC3D;
  text-align: center;
  padding: 20px;
  border-radius: 50px;
  color: #572E2E;

  @media (max-width: 768px) {
    padding: 40px 20px; // Increased padding for mobile
  }
`;

const HeroText = styled.h1`
  font-size: 8em;
  position: relative;
  z-index: 1;
  font-family: lumispeak;
  margin-top: 8%;

  @media (max-width: 768px) {
    font-size: 4em; // Smaller font for mobile
    margin-top: 4%; // Adjusting top margin for mobile
  }
`;

const HeroImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 100%;

  @media (max-width: 768px) {
    position: static;
    transform: none;
    width: 80%;
    margin-top: 20px; // Space between text and image
  }
`;

const Hero = () => {
  const containerRef = useRef();

    useEffect(() => {
    const adjustHeight = () => {
      const container = containerRef.current;
      if (container) {
        const textHeight = container.querySelector('h1').offsetHeight;
        const image = container.querySelector('img');
        const imageHeight = image.offsetHeight;
  
        const totalHeight = textHeight + imageHeight;
        container.style.height = `${totalHeight + 82}px`;
      }
    };
  
    const container = containerRef.current;
    const image = container.querySelector('img');
  
    // Attach event listener to image load
    const handleImageLoad = () => adjustHeight();
    image.addEventListener('load', handleImageLoad);
  
    // Call adjustHeight on window resize
    window.addEventListener('resize', adjustHeight);
    adjustHeight(); // Initial adjustment
  
    return () => {
      window.removeEventListener('resize', adjustHeight);
      image.removeEventListener('load', handleImageLoad);
    };
  }, []);

  return (
    <HeroContainer ref={containerRef}>
      <HeroText>THE CUTEST CULT ON THE INTERNET</HeroText>
      <HeroImage src={"/Lumis/lumi-hero.png"} alt="Hero Image" />
    </HeroContainer>
  );
};

export default Hero;
