import React, {useEffect, useState} from "react";
import LumiNavbar from "../components/Navbar";
import LumiGrid from "../components/LumiGrid";
import { SizedBox } from "../components/SizedBox";
import PsyopGrid from "../components/PsyopGrid";
import FAQ from "../components/FAQ";
import Hero from "../components/Hero";
import LumiSidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { isMobile } from "react-device-detect";
import CommunityGrid from "../components/CommunityGrid";
import Popup from "../components/NiftyPopup";


export default function Home() {
    const [showPopup, setShowPopup] = useState(false);

    function checkAndSetFirstVisitCookie() {
        const cookieName = 'lumiSzn1';
        const cookie = document.cookie.split('; ').find(row => row.startsWith(`${cookieName}=`));
      
        if (!cookie) {
          const now = new Date();
          const expires = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
          document.cookie = `${cookieName}=true; expires=${expires.toUTCString()}; path=/`;
          localStorage.setItem('lumiSzn1', now.toISOString());
      
          return false;
        }
        return true;
      }


    useEffect(() => {
        const cookieExists = checkAndSetFirstVisitCookie();
        if (!cookieExists) {
            setShowPopup(true);
        }
      }, []);

    return(
        <div style={{width: isMobile ? "90%" : "80%", margin: "auto"}}>
        <LumiSidebar />
        <LumiNavbar />
        {showPopup ? <Popup /> : <></>}
        
        <div id="about">
            <SizedBox height={"100px"} />
            <Hero />
        </div>
        <SizedBox height={"100px"} />
        <div id="lumis">
            <SizedBox height={"100px"} />
            <LumiGrid />
        </div>
        <SizedBox height={"100px"} />
        <div id="psyop">
            <SizedBox height={"100px"} />
            <PsyopGrid />
        </div>
        <SizedBox height={"100px"} />
        <div id="community">
            <SizedBox height={"100px"} />
            <CommunityGrid />
        </div>
        <SizedBox height={"100px"} />
        <div id="faq">
            <SizedBox height={"100px"} />
            <FAQ/>
        </div>
        <SizedBox height={"30px"} />
        <Footer />
        </div>
    );
}
