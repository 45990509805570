import { Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { BrowserView, MobileView } from "react-device-detect";
import { SizedBox } from "./SizedBox";
import { useEffect, useState } from "react";

const shadow = "/LumiBuilder/shadow.png";

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
  margin-top: 20px;
  zIndex: -1;
`;

const ImageContainerMeme = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  width: 100%;
  position: relative;
  
`;

const Image = styled.img`
  width: 100%;
  margin: auto;
  object-fit: cover;
  cursor: pointer;
  zIndex: -1;
`;

const LumiPart = styled.img`
  position: "relative";
  zIndex: 1;
  cursor: "pointer";
  width: "100%";
  `;

const ImageDescription = styled.p`
  text-align: center;
  font-family: 'Hansip', sans-serif;
  margin: 0;
  font-size: 10px;
`;

const StyledButton = styled.button`
    background-color: #fabf09;
    color: #00000;
    padding: 0px 15px;
    border: 1px solid #fabf09;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    font-family: 'GoodTiming', sans-serif;
    margin: 20px 10px;
    height: 30px;
`;



export default function ImageTray({ dir, folder, onClick, text }) {
  const [currentPage, setCurrentPage] = useState(0);

  function changePage(num)
  {
    if(currentPage + num >= 0 && currentPage + num < pages.length)
      setCurrentPage(currentPage + num);
  }
  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return r.keys();
  }

  let parentdir = "";

  switch(folder)
  {
    case "memefolder":
      parentdir = "/memefolder_images/";
      break;
    case "LumiBuilder":
      parentdir = "/LumiBuilder/";
      break;
    case "GMMachineImages":
      parentdir = "/GMMachineImages/";
      break;
  }

  let images = [];
  let imgtiles = [];

  switch (dir) {
    //MemeFolder
    case "GM":
      images = importAll(require.context('../../public/memefolder_images/GM', false, /.(png|jpe?g|svg)$/));
      break;
    case "GF":
      images = importAll(require.context('../../public/memefolder_images/GF', false, /.(png|jpe?g|svg)$/));
      break;
    case "BULL":
      images = importAll(require.context('../../public/memefolder_images/BULL', false, /.(png|jpe?g|svg)$/));
      break;
    case "BEAR":
      images = importAll(require.context('../../public/memefolder_images/BEAR', false, /.(png|jpe?g|svg)$/));
      break;

    //LumiBuilder
    case "background":
      images = importAll(require.context('../../public/LumiBuilder/background', false, /\.(png|jpe?g|svg)$/));
      break;
    case "base":
      images = importAll(require.context('../../public/LumiBuilder/base', false, /\.(png|jpe?g|svg)$/));
      break;
    case "body trait":
      images = importAll(require.context('../../public/LumiBuilder/body trait', false, /\.(png|jpe?g|svg)$/));
      break;
    case "expressions":
      images = importAll(require.context('../../public/LumiBuilder/expressions', false, /\.(png|jpe?g|svg)$/));
      break;
    case "glasses":
      images = importAll(require.context('../../public/LumiBuilder/glasses', false, /\.(png|jpe?g|svg)$/));
      break;
    case "top head":
      images = importAll(require.context('../../public/LumiBuilder/top head', false, /\.(png|jpe?g|svg)$/));
      break;

      //GMMachine
      case "items":
        images = importAll(require.context('../../public/GMMachineImages/items', false, /\.(png|jpe?g|svg)$/));
      break;
  }

  const imgContainerStyle = {
    position: "relative",
  };
  
  const shadowStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    zIndex: dir == "background" ? 3 : 0
  };
  
  const textContainerStyle = {
    textAlign: "center",
    color: "black",
    width: "25%"
  };
  
  const mainImageStyle = {
    position: "relative",
    zIndex: 1,
    cursor: "pointer",
    width: "100%"
  };

  images.forEach(img => {
    let src = img;
    src = src.substring(2);
    src = parentdir + dir + "/" + src;
    let creator = img;
    let memename = img;

    let imgName = img.slice(2);
    const parts = imgName.split('-');
    if (parts[1]) {
      parts[1] = parts[1].slice(0, -4);
      memename = parts[0];
      creator = parts[1];
    }

    imgtiles.push(
      <Col style={textContainerStyle}  onClick={() => onClick(src)}>

        <div style={imgContainerStyle}>
        {folder === "LumiBuilder" ? <img src={shadow} style={shadowStyle} /> : <></>}
        {folder === "GMMachineImages" ? <img src={shadow} style={shadowStyle} /> : <></>}
        {folder === "memefolder" ? <>
        <ImageContainerMeme><a href={src} download={imgName + ".png"}><Image src={src}/></a></ImageContainerMeme>
        <SizedBox height={10}/>
          <ImageDescription>
            {memename} "by" <a href={"https://twitter.com/" + (creator == "LUMI" ? "LumiParadox" : creator)}>@{creator}</a>
          </ImageDescription>
          <SizedBox height={10}/>
          </>
           :
        <ImageContainer>
            <img src={src} style={mainImageStyle}/>
          </ImageContainer>
        }
        </div>
      </Col>
    );
  });

  let pages = [];
  let rows = [];
  let cols = [];
  
  for (let i = 0; i < imgtiles.length; i++) {
    cols.push(imgtiles[i]);
  
    if (cols.length === (folder === "memefolder" ? 2 : 5)) {
      rows.push(<Row>{cols}</Row>);
      cols = [];
  
      // Add condition to check if rows.length is 5 for mobile
      if (rows.length === 5) {
        pages.push(<div>{rows}</div>);
        rows = [];
      }
    }
  }
  
  // Fill remaining columns with empty Col components
  if (cols.length > 0) {
    for (let j = cols.length; j < (folder === "memefolder" ? 2 : 5); j++) {
      cols.push(<Col></Col>);
    }
    rows.push(<Row>{cols}</Row>);
  }
  // Fill remaining rows with empty Row components
  while (rows.length < 5) {
    rows.push(<Row></Row>);
  }
  
  // Push the remaining rows into pages
  pages.push(<div>{rows}</div>);
  
  useEffect(() => {
    setCurrentPage(0);
  }, [dir]);
  return (
<div style={{ textAlign: 'center', marginTop: '0px' }}> {/* Center align buttons and add top margin */}
    {pages[currentPage]}
    {pages.length > 1 ? (
        <>
            <StyledButton onClick={() => changePage(-1)}>Prev</StyledButton>
            <StyledButton onClick={() => changePage(1)}>Next</StyledButton>
        </>
    ) : (
        <></>
    )}
</div>
  );
}