import styled from "styled-components";

const StyledIcon = styled.img`
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  margin: 5px 0;
  z-index: 11;
  ${(props) => props.style}
`;

const Icon = ({src, width, style}) => {
    return <StyledIcon src={src} width={width} style={style}/>
}

export default Icon;