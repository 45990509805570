import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import styled from "styled-components";

const HeroContainer = styled.div`
  padding-top: 100px; /* Increase padding on top */
`;

const WelcomeText = styled.h1`
  padding-top: 1px;
  color: #572e2e;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const WelcomeSubtext = styled.p`
  color: #572e2e;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 50px; /* Increase margin between main text and rectangles */
`;

const RectangleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px; /* Set maximum width */
  margin-left: auto;
  margin-right: auto;
`;

const RectangleContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;


const Rectangle = styled.a`
  position: relative;
  background-color: ${({ clickable }) => clickable ? '#572e2e' : '#CCCCCC'}; // Use grey color if not clickable
  color: #fff;
  font-size: 1.5rem;
  font-family: lumispeak;
  padding: 40px 60px; /* Increase padding to adjust height */
  border-radius: 20px;
  text-decoration: none;
  text-align: left;
  overflow: hidden;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  margin: 20px;
  width: 500px; /* Fixed width for each rectangle */
  box-sizing: border-box; /* Include padding and border in width */
  transition: filter 0.3s ease, border-color 0.3s ease, transform 0.3s ease; /* Add transition effect */
  border: 2px solid #572e2e; /* Initial border style */
  z-index: 1; /* Ensure the rectangle is above the overlay */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #572e2e;
    transition: background-color 0.3s ease; /* Add transition effect */
    z-index: -1; /* Ensure the overlay is below the text */
  }

  &:hover:before {
    background-color: rgba(87, 46, 46, 0); /* Remove semi-transparent color on hover */
  }

  &:hover {
    filter: grayscale(0%); /* Remove grayscale effect on hover */
    border-color: #fff; /* Change border color on hover */
    transform: scale(1.02); /* Add scale transformation on hover */
  }

  ${({ clickable }) => !clickable && `
    pointer-events: none; /* Disable pointer events if not clickable */
  `}
`;

const TextContainer = styled.div`
  margin-top: 20px;
`;

const MainText = styled.p`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const SubText = styled.p`
  color: #fff;
  font-size: 1rem;
`;

const RectangleButton = ({ mainText, subText, backgroundImage, href }) => {
  // Determine if the rectangle is clickable or not
  const isClickable = href !== "";

  return (
    <Rectangle
      href={isClickable ? href : undefined} // Remove href attribute if not clickable
      backgroundImage={backgroundImage}
      clickable={isClickable} // Pass clickable flag as a prop
    >
      <TextContainer>
        <MainText>{mainText}</MainText>
        <SubText>{subText}</SubText>
      </TextContainer>
    </Rectangle>
  );
};

export default function DashboardHero() {
  return (
    <>
      <HeroContainer>
        <WelcomeText>Welcome to the Dashboard</WelcomeText>
        <WelcomeSubtext>Experiment with some of the cool stuff we have built</WelcomeSubtext>
        <RectangleContainer>
          <RectangleButton
            mainText="Meme Machine"
            subText="Create memes in 3 clicks"
            backgroundImage="DashboardImages/mememachine.png"
            href="/mememachine"
          />
          <RectangleButton
            mainText="Meme Folder"
            subText="Share memes quickly"
            backgroundImage="DashboardImages/memefolder.png"
            href="/memefolder"
          />
          <RectangleButton
            mainText="Coming Soon"
            subText="Build your dream Lumi"
            backgroundImage="DashboardImages/lumibuilder.png"
            href=""
          />
          <RectangleButton
            mainText="Coming Soon"
            subText="Create content in one click"
            backgroundImage="DashboardImages/gmmachine.png"
            href=""
          />
        </RectangleContainer>
      </HeroContainer>
    </>
  );
}
