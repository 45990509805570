import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Construction from './pages/Construction';
import { createGlobalStyle } from 'styled-components';
import MemeFolder from './pages/MemeFolder';
import LumiBuilder from "./pages/LumiBuilder";
import Dashboard from "./pages/Dashboard";
import MemeMachine from "./pages/MemeMachine";
import GMMachine from './pages/GMMachine';
import PSHome from './pages/PSHome'; // Import PSHome component
import Studio from './pages/Studio';

const GlobalStyle = createGlobalStyle`
  h1, h2, h3 {
    font-family: lumispeak;
    color: #572E2E;
    
    @media (min-width: 768px) {
      // Desktop text sizes
      h1 { font-size: 8em; }
    }

    @media (max-width: 767px) {
      // Mobile text sizes
      h1 { font-size: 4em; }
      h2 { font-size: 2em; }
      h3 { font-size: 5px; }
    }
  }

  h4, h5, h6 {
    @media (min-width: 768px) {
      h4 { font-size: 4em; }
    }

    @media (max-width: 767px) {
      h4 { font-size: 5px; !important }
      h5 { font-size: .7rem; !important } 
      h6 { font-size: 5px; }
    }
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} /> 
        <Route path="/construction" element={<Construction />} />
        <Route path="/memefolder" element={<MemeFolder />} />
        <Route path="/lumibuilder" element={<LumiBuilder />} />
        <Route path="/mememachine" element={<MemeMachine />} />
        <Route path="/gmmachine" element={<GMMachine />} />
        <Route path="/pshome" element={<PSHome />} /> {/* Add PSHome route */}
        <Route path="/studio" element={<Studio />} />
      </Routes>
    </>
  );
}

export default App;
