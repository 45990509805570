import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { fabric } from 'fabric';

const PSCanvas = ({
  setSelectedElement,
  activeTool,
  shapeType,
  shapeOpacity,
  shapeColor
}) => {
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    const initCanvas = new fabric.Canvas(canvasElement, {
      backgroundColor: 'black',
      width: 1000,
      height: 600,
    });
    setCanvas(initCanvas);
    window.fabricCanvas = initCanvas; // Expose canvas globally for other components

    const handleResize = () => {
      const { clientWidth, clientHeight } = canvasElement.parentNode;
      initCanvas.setDimensions({ width: clientWidth - 30, height: clientHeight - 30 });
      initCanvas.renderAll();
    };

    handleResize();

    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedHandleResize);

    initCanvas.on('selection:created', (e) => {
      setSelectedElement(e.selected ? e.selected[0] : null);
    });

    initCanvas.on('selection:updated', (e) => {
      setSelectedElement(e.selected ? e.selected[0] : null);
    });

    initCanvas.on('selection:cleared', () => {
      setSelectedElement(null);
    });

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      initCanvas.dispose();
    };
  }, [setSelectedElement]);

  useEffect(() => {
    if (canvas && activeTool === 'shape') {
      addShape(shapeType);
    }
  }, [canvas, activeTool, shapeType]);

  const addShape = (type) => {
    let shape;
    switch (type) {
      case 'triangle':
        shape = new fabric.Triangle({ width: 50, height: 50, fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
      case 'circle':
        shape = new fabric.Circle({ radius: 25, fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
      case 'hexagon':
        shape = new fabric.Polygon([
          { x: 25, y: 0 },
          { x: 50, y: 15 },
          { x: 50, y: 45 },
          { x: 25, y: 60 },
          { x: 0, y: 45 },
          { x: 0, y: 15 }
        ], { fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
      default:
        shape = new fabric.Rect({ width: 50, height: 50, fill: shapeColor, opacity: shapeOpacity, left: 100, top: 100 });
        break;
    }
    canvas.add(shape);
    canvas.setActiveObject(shape);
    canvas.renderAll();
  };

  const handleKeyDown = (e) => {
    const activeObject = canvas?.getActiveObject();

    if (activeObject && activeObject.isEditing) {
      return; // Do not perform any actions if the active object is being edited
    }

    if (e.key === 'Delete' || e.key === 'Backspace') {
      if (activeObject) {
        if (activeObject.type === 'activeSelection') {
          activeObject.forEachObject((obj) => {
            canvas.remove(obj);
          });
        } else {
          canvas.remove(activeObject);
        }
        canvas.discardActiveObject();
        canvas.renderAll();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas]);

  return <Canvas ref={canvasRef} />;
};

const Canvas = styled.canvas`
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
`;

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default PSCanvas;
