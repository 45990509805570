import {Col, Nav, Row} from "react-bootstrap";
import { SizedBox } from "../components/SizedBox";
import { BrowserView, MobileView } from "react-device-detect";
import { useEffect, useState } from "react";
import mergeImages from 'merge-images';
import ImageTray from "../components/ImageTray";
import styled from "styled-components";
import csvFile from "../contracts/metadata.csv";
import { readString } from "react-papaparse";
import Icon from "../components/Icon";
import DashboardNavbar from '../components/Dashboard_Navbar';


const Container = styled.div`
  text-align: center;
`;

const Title = styled.h1`
font-size: 3.5em;
  text-transform: uppercase;
  font-family: lumispeak; 
`

const MetallicButton = styled.button`
  background-color: ${props => props.selected ? '#fabf09' : '#572E2E'};
  color: ${props => props.selected ? '#00000' : '#fff'};
  padding: 10px 20px;
  border: 3px solid ${props => props.selected ? '#fabf09' : 'white'};
  border-radius: 20px; /* Rounded edges */
  outline: none;
  font-weight: bold;
  font-family: lumispeak;
  &:active {
    background-color: #fabf09; 
  }
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? .5 : 1)};
`;

export default function BuildALumi() {

    const [currentAccount, setCurrentAccount] = useState(null);
    const [base, setBase] = useState("/LumiBuilder/base/graves.png");
    const [background, setBackground] = useState("/LumiBuilder/background/fabf09.png");
    const [body, setBody] = useState("/LumiBuilder/body trait/none.png");
    const [hat, setHat] = useState("/LumiBuilder/top head/none.png");
    const [expression, setExpression] = useState("/LumiBuilder/expressions/-.png");
    const [glasses, setGlasses] = useState("/LumiBuilder/glasses/none.png");
    const [image, setImage] = useState(null);
    const [partsTray, setPartsTray] = useState(null);
    const [b64, setB64] = useState(null);
    const [currentLumi, setCurrentLumi] = useState(-1);

    const changePartsTray = (dir) => {
        setPartsTray(<ImageTray folder="LumiBuilder" text={false} dir={dir} onClick={(item) => {updateItem({item});}} bg={background}/>);
    }
    
    const updateItem = ({item}) => 
    {
        let itemSlot = "";
        let slot = item.split("/");
        itemSlot = slot[slot.length - 2];
        switch(itemSlot){
            case "bg":
                setBackground(item);
                break;

            case "base":
                setBase(item);
                break;

            case "body trait":
                setBody(item);
                break;

            case "expressions":
                setExpression(item);
                break;
            
            case "glasses":
                setGlasses(item);
                break;

            case "top head":
                setHat(item);
                break;

            default:
                console.log("Slot: " + slot);
                break;
                    
        }
    }

    const updateImage = async () => {
        let b64t = "";
        b64t = await mergeImages([background, base, expression, body, hat, glasses]);
        setB64(b64t);
        setImage(<img src={b64t} style={{width: "100%", display: "block"}} alt={"Lumi"}/>);
    }

    const searchCSV = async (background, base, body, expression, hat, glasses) => {
        background = background.split("/")[3].slice(3,-4);
        base = base.split("/")[3].slice(0,-4);
        body = body.split("/")[3].slice(0,-4);
        expression = expression.split("/")[3].slice(0,-4);
        hat = hat.split("/")[3].slice(0,-4);
        glasses = glasses.split("/")[3].slice(0,-4);

        const response = await fetch(csvFile);
        const csvText = await response.text();
      
        const csvData = readString(csvText).data;
        setCurrentLumi(-1);
        for (let i = 0; i < csvData.length; i++) {
          const row = csvData[i];
          const [number, bg, bs, bd, ex, th, gl] = row;
      
          if (
            bs === base &&
            bd === body &&
            ex === expression &&
            th === hat &&
            gl === glasses
          ) {
            console.log("This is Lumi#" + number);
            setCurrentLumi(number);
          }
        }

      };

      const sendToNFT = (platform) => 
      {
        if(currentLumi !== -1){
            switch(platform)
            {
                case "blur":
                    window.location.href = "https://blur.io/asset/0x15ec431c24e791574f5dd9e16f7f2cd5bfffbb27/" + currentLumi;
                    break;
                case "opensea":
                    window.location.href = "https://opensea.io/assets/ethereum/0x15ec431c24e791574f5dd9e16f7f2cd5bfffbb27/" + currentLumi;
                    break;
            }
        }
      }

    useEffect(() => {
        updateImage();
        const number = searchCSV(background, base, body, expression, hat, glasses);
    }, [background, body, base, expression, hat, glasses])

    useEffect(() => {
        updateImage();
    }, [])

        return(
                <>
                <BrowserView>
                    <div style={{width: "90%", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "5%"}}>
                    <div style={{padding: "25px"}}>
                        <Col>
                        
                            <Row>
                            <Container><Title><br></br></Title></Container>
                            <Container><h3>Select traits to build a custom Lumi, then click on the image to save it!</h3></Container>
                                
                            </Row>
                            <Col>
                                </Col>
                                <SizedBox height={"25px"} />
                            <Row>
                            <Col>
                            <div style={{ padding: "5px", backgroundColor: "#572e2e", width: "100%", margin: "auto", padding: "20px", zIndex: "-100", borderRadius: "20px"}}>
                                {image ? 
                                <Col>
                                    <Row>
                                        {

                                        }
                                        <Col>
                                            <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('base');}}>
                                                Base
                                            </MetallicButton>
                                        </Col>
                                        <Col>
                                            <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('expressions');}}>
                                                Expression
                                            </MetallicButton>
                                        </Col>
                                        <Col>
                                            <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('body trait');}}>
                                                Body
                                            </MetallicButton>
                                        </Col>
                                        <Col>
                                            <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('glasses');}}>
                                                Glasses
                                            </MetallicButton>
                                        </Col>
                                        <Col>
                                            <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('top head');}}>
                                                Hat
                                            </MetallicButton>
                                        </Col>
                                    </Row>
                                    <SizedBox height={"25px"} />
                                    <Row>
                                        <Col>
                                            {partsTray}
                                        </Col>
                                    </Row>
                                </Col> 
                                : <p></p>}
                            </div>
                            </Col>
                            <Col>
                                    <Row>
                                        { image ? <h4></h4> : <></>}
                                        <div style={{ padding: "5px", width: "70%", margin: "auto", backgroundColor:"rgba(255,255,255)"}}>               
                                            <div style={{position: "relative"}}>
                                                <a href={b64} download="Lumi.png">{image} </a>
                                            </div>
                                        </div>
                                    </Row>
                                    <SizedBox height="50px"/>
                                    <Row>
    <Col style={{ textAlign: 'center' }}> {/* Added style */}
        <MetallicButton
            style={{ width: '20%' }}
            disabled={currentLumi === -1}
            onClick={() => { sendToNFT("blur") }}
        >
            <Icon src="/Icons/blur.png" style={{ filter: "brightness(0) invert(1)" }} />
        </MetallicButton>
        <MetallicButton
            style={{ width: '20%' }}
            disabled={currentLumi === -1}
            onClick={() => { sendToNFT("opensea") }}
        >
            <Icon src="/Icons/os.png" style={{ filter: "brightness(0) invert(1)" }} />
        </MetallicButton>
    </Col>
</Row>

                                </Col>
                            </Row>
                            <SizedBox height={"50px"} />
                            <SizedBox height={"75px"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </BrowserView>

                    <MobileView>
                    <div style={{width: "90%", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "5%"}}>
                    <div style={{padding: "5px"}}>
                        <Col>
                            <Row>
                                <h1>Lumi Builder</h1>
                            </Row>
                            <Col>
                                </Col>
                                <SizedBox height={"25px"} />
                               
                            <Row>
                                <Col>
                                { image ? <h4></h4> : <></>}
                                <div style={{ padding: "5px", width: "80%", margin: "auto", backgroundColor:"rgba(255,255,255)"}}>
                                            
                                        <div style={{position: "relative"}}>
                                        <a href={b64} download="Lumi.png">{image} </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <SizedBox height={"50px"} />
                            <Row>
                                <Col style={{ textAlign: 'center' }}> {/* Added style */}
                                    <MetallicButton
                                        style={{ width: '20%' }}
                                        disabled={currentLumi === -1}
                                        onClick={() => { sendToNFT("blur") }}
                                    >
                                        <Icon src="/Icons/blur.png" style={{ filter: "brightness(0) invert(1)" }} />
                                    </MetallicButton>
                                    <MetallicButton
                                        style={{ width: '20%' }}
                                        disabled={currentLumi === -1}
                                        onClick={() => { sendToNFT("opensea") }}
                                    >
                                        <Icon src="/Icons/os.png" style={{ filter: "brightness(0) invert(1)" }} />
                                    </MetallicButton>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                            <Col>
                            <div style={{ padding: "5px", backgroundColor: "#572e2e", width: "100%", margin: "auto", zIndex: "-100",  borderRadius: "20px"}}>
                                {image ? 
                                <Col>
                                <Row>
                                    {/*<Col>
                                        <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('background');}}>
                                            Background
                                        </MetallicButton>
                                    </Col>*/}
                                    <Col>
                                        <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('base');}}>
                                            Base
                                        </MetallicButton>
                                    </Col>
                                    <Col>
                                        <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('expressions');}}>
                                            Expression
                                        </MetallicButton>
                                    </Col>
                                    <Col>
                                        <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('body trait');}}>
                                            Body
                                        </MetallicButton>
                                    </Col>
                                    <Col>
                                        <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('glasses');}}>
                                            Glasses
                                        </MetallicButton>
                                    </Col>
                                    <Col>
                                        <MetallicButton style={{ width: '100%', cursor: 'pointer' }} onClick={() => {changePartsTray('top head');}}>
                                            Hat
                                        </MetallicButton>
                                </Col>
                                </Row>
                                    <SizedBox height={"25px"} />
                                    <Row>
                                        <Col>
                                            {partsTray}
                                        </Col>
                                    </Row>
                                </Col> 
                                : <p></p>}
                            </div>
                            </Col>
                            </Row>
                            <SizedBox height={"50px"} />
                            <SizedBox height={"75px"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </MobileView>
            </>
        )
}