import React from 'react';
import styled from 'styled-components';
import { FaTextHeight, FaPencilAlt, FaSquare } from 'react-icons/fa';

const Toolbar = ({ addTextToCanvas, startPainting, startAddingShape, activeTool }) => {
  return (
    <ToolbarContainer>
      <ToolButton
        onClick={addTextToCanvas}
        title="Add Text"
        active={activeTool === 'text'}
      >
        <FaTextHeight />
      </ToolButton>
      <ToolButton
        onClick={startPainting}
        title="Paint"
        active={activeTool === 'paint'}
      >
        <FaPencilAlt />
      </ToolButton>
      <ToolButton
        onClick={startAddingShape}
        title="Shape"
        active={activeTool === 'shape'}
      >
        <FaSquare />
      </ToolButton>
    </ToolbarContainer>
  );
};

export default Toolbar;

const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #333;
  padding: 10px;
  border-radius: 8px;
  align-items: center;
`;

const ToolButton = styled.button`
  background: ${({ active }) => (active ? '#555' : '#444')};
  color: #fff;
  border: none;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background: #555;
  }

  svg {
    font-size: 1.5em;
  }
`;
