import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fabric } from 'fabric'; 

const fonts = ['Comic Sans MS', 'Times New Roman', 'lumispeak'];
const colorsTop = ['#ffe0b2', '#572E2E', 'white'];
const colorsBottom = [];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;ƒ
  max-width: 100%;
  padding: 0 5%;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 444px;
`;
const StyledButton = styled.button`
  margin: 0.3em;
  border: 2px solid #ffe0b2;
  border-radius: 10px;
  cursor: pointer;
  width: 30%;
  height: 20%;
  max-width: 130px;
  max-height: 50px;
  font-family: lumispeak;
  background-color: ${({ color }) => color};
  color: white;
  padding: 0.5em 1.3em; /* Adjust padding for better appearance */
  font-size: 1em; /* Adjust font size for better appearance */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add transition for smoother hover effect */

  &:hover {
    background-color: #572E2E; /* Change background color on hover */
    border-color: #572E2E; /* Change border color on hover */
    color: #fff; /* Change text color on hover */
  }
`;

const SaveImageButton = styled.button`
  padding: 8px 16px;
  background-color: #ffe0b2;
  font-family: lumispeak;
  color: #572E2E;
  border-color: #572E2E;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  max-width: 444px;
  transition:  0.3s ease;

  &:hover {
    background-color: #572E2E;
    font-family: lumispeak;
    color: #fff;
    border-color: #572E2E;
  }
`;

const MainImageContainer = styled.div`
  position: relative;
  margin-top: 10px;
  width: 100%;
  max-width: 444px;
  background-color: #572E2E; /* Set the desired background color */
  border-radius: 10px;
`;
const ImageSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #572E2E;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 444px;
`;

const ImageWrapper = styled.div`
  margin-right: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  width: ${({ width }) => `${width}px`};
`;

const GalleryImage = styled.img`

  border-radius: 10px;
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const Canvas = styled.canvas`

  border: 5px solid #572E2E;
  border-radius: 10px 10px 0 0;
  margin-top: 10px;
  width: 100%;
  max-width: 100%;
`;

const TextBoxContainer = styled.div`
  color: #ffe0b2;
  background-color: #572E2E;
  border-radius: 0 0 10px 10px;
  border: 10px solid #572E2E;
  width: 100%;
  max-width: 444px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextBox = styled.textarea`
  background-color: #ffe0b2;
  color: #000;
  width: 100%;
  height: 20%;
  resize: none;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 5px solid #572E2E;
  border-radius: 10px;
  text-align: center;
  line-height: 40%;
`;

const AddTextButton = styled.button`
  padding: 8px 16px;
  background-color: #ffe0b2;
  font-family: lumispeak;
  color: #572E2E;
  border-color: #572E2E;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  max-width: 444px;
  transition: 0.3s ease;

  &:hover {
    background-color: #572E2E;
    font-family: lumispeak;
    color: #fff;
    border-color: #572E2E;
  }
`;

const StrokeSlider = styled.input.attrs({
  type: 'range',
  min: 1,
  max: 10,
  step: 1,
})`
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ExpandedOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const LeftArrowButton = styled.button`
  background-color: transparent;
  border: none;
  color: #ffe0b2;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: lumispeak;

  &:hover {
    color: #fff;
  }
`;

const RightArrowButton = styled.button`
  background-color: transparent;
  border: none;
  color: #ffe0b2;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: lumispeak;
  &:hover {
    color: #fff;
  }
`;

const MemeMachine = () => {
  const canvasRef = React.useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [text, setText] = useState('');
  const [visibleImageStartIndex, setVisibleImageStartIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState('black');
  const [selectedFont, setSelectedFont] = useState('Arial');
  const [showColorOptions, setShowColorOptions] = useState(false); 
  const [showFontOptions, setShowFontOptions] = useState(false); 
  const [selectedFontColor, setSelectedFontColor] = useState('black');
  const [showStrokeOptions, setShowStrokeOptions] = useState(false); 
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [activeText, setActiveText] = useState(null);
  const [currentOption, setCurrentOption] = useState(null);

  const imageContext = require.context('../../public/mememachine', true, /\.(jpg|jpeg|png)$/);
  const imageUrls = imageContext.keys().map(imageContext);

  useEffect(() => {
    const initializeCanvas = () => {
      if (canvasRef.current) {
        const canvasContainerWidth = canvasRef.current.offsetWidth;
        const canvasSize = Math.min(canvasContainerWidth, 444); 
  
        const newCanvas = new fabric.Canvas(canvasRef.current, {
          width: canvasSize,
          height: canvasSize,
        });

        fabric.Image.fromURL("/blank.png", (img) => {
          const canvasContainerWidth = canvasRef.current.offsetWidth;
          const canvasContainerHeight = canvasRef.current.offsetHeight;
          
          const aspectRatio = img.width / img.height;
          
          const containerAspectRatio = canvasContainerWidth / canvasContainerHeight;
    
          let scaledWidth, scaledHeight;
          
          if (aspectRatio >= containerAspectRatio) {
            
            scaledWidth = canvasContainerWidth;
            scaledHeight = canvasContainerWidth / aspectRatio;
          } else {
            
            scaledHeight = canvasContainerHeight;
            scaledWidth = canvasContainerHeight * aspectRatio;
          }
    
          img.set({
            scaleX: scaledWidth / img.width,
            scaleY: scaledHeight / img.height,
            left: (canvasContainerWidth - scaledWidth) / 2, 
            top: (canvasContainerHeight - scaledHeight) / 2, 
            selectable: false,
          });
          newCanvas.add(img);

          setCanvas(newCanvas);
          bringTextsToFront(canvas);
        });
      }
    };
  
    if (canvasRef.current && !canvas) {
      initializeCanvas();
    }
  
    const handleResize = () => {
      //initializeCanvas();
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      if (canvas) {
        canvas.dispose();
      }
    };
  }, [canvasRef]);

  const bringTextsToFront = (canvas) => {
    if(canvas != null)
    {
    canvas.getObjects().forEach((obj) => {
      if (obj.type === 'textbox') {
        canvas.bringToFront(obj);
      }
    });
    canvas.renderAll();
    }
  };


  const handleImageClick = (imageUrl) => {
    if (canvas) {
      //canvas.clear();
      fabric.Image.fromURL(imageUrl, (img) => {
        const canvasContainerWidth = canvasRef.current.offsetWidth;
        const canvasContainerHeight = canvasRef.current.offsetHeight;
        
        const aspectRatio = img.width / img.height;
        
        const containerAspectRatio = canvasContainerWidth / canvasContainerHeight;
  
        let scaledWidth, scaledHeight;
        
        if (aspectRatio >= containerAspectRatio) {
          
          scaledWidth = canvasContainerWidth;
          scaledHeight = canvasContainerWidth / aspectRatio;
        } else {
          
          scaledHeight = canvasContainerHeight;
          scaledWidth = canvasContainerHeight * aspectRatio;
        }
  
        img.set({
          scaleX: scaledWidth / img.width,
          scaleY: scaledHeight / img.height,
          left: (canvasContainerWidth - scaledWidth) / 2, 
          top: (canvasContainerHeight - scaledHeight) / 2, 
          selectable: false,
        });
        
        canvas.add(img);
        bringTextsToFront(canvas);
      });
    }
  };
  

  const handleAddText = () => {
    if (canvas && text.trim() !== '') {
      const textObject = new fabric.Textbox(text, {
        left: 50,
        top: 50,
        width: 100,
        height: 100,
        fontSize: 20,
        textAlign: 'center',
        fontFamily: selectedFont,
        fill: selectedFontColor,
        strokeWidth: strokeWidth, 
        stroke: 'black', 
      });
      canvas.add(textObject);
      canvas.setActiveObject(textObject);
      bringTextsToFront(canvas)
      canvas.renderAll();
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleColorButtonClick = (color) => {
    setSelectedColor(color); 
    setShowColorOptions(false);
    setCurrentOption(null);
    setSelectedFontColor(color); 
    
    const activeObject = canvas.getActiveObject();
    if(activeObject && activeObject.type === 'textbox')
    {
      activeObject.set({
        fill: color,
      });
    }
    canvas.renderAll();
  };

  const handleFontButtonClick = (font) => {
    setSelectedFont(font);
    setShowFontOptions(false);
    setCurrentOption(null);

    const activeObject = canvas.getActiveObject();
    if(activeObject && activeObject.type === 'textbox')
    {
      activeObject.set({
        fontFamily: font,
      });
    }
    canvas.renderAll();
  };

  const showOptions = (option) =>
  {
    if(currentOption !== option)
    {
        setCurrentOption(option);
        setShowColorOptions(option == "Color");
        setShowStrokeOptions(option == "Stroke");
        setShowFontOptions(option == "Font");
    }
    else
    {
      setCurrentOption(null);
      setShowColorOptions(false);
      setShowStrokeOptions(false);
      setShowFontOptions(false);
    }
    
  }

  const handleStrokeChange = (event) => {
    const activeObject = canvas.getActiveObject();
    if(activeObject && activeObject.type === 'textbox')
    {
      setStrokeWidth(event.target.value / 10);
      activeObject.set({
        strokeWidth: event.target.value / 10,
      });
    }
    canvas.renderAll();
  };

  const handleLeftArrowClick = () => {
    setVisibleImageStartIndex(Math.max(0, visibleImageStartIndex - 1));
  };

  const handleRightArrowClick = () => {
    setVisibleImageStartIndex(Math.min(imageUrls.length - 4, visibleImageStartIndex + 1));
  };

  const handleSaveImage = () => {
    if (canvas) {
      canvas.clone((clonedCanvas) => {
        const exportWidth = 1024;
        const exportHeight = 1024;
  
        const scaleX = exportWidth / canvas.getWidth();
        const scaleY = exportHeight / canvas.getHeight();
        const scale = Math.min(scaleX, scaleY);
  
        clonedCanvas.setWidth(exportWidth);
        clonedCanvas.setHeight(exportHeight);
  
        clonedCanvas.forEachObject((obj) => {
          obj.scaleX *= scale;
          obj.scaleY *= scale;
          obj.left *= scale;
          obj.top *= scale;
          obj.setCoords();
        });
  
        clonedCanvas.renderAll();
  
        const dataURL = clonedCanvas.toDataURL({
          format: 'png'
        });
  
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'Lumi_MemeMachine.png';
        link.click();
      });
    }
  };
  
  const copyToClipboard = async() =>
  {
    const dataURL = canvas.toDataURL();
    fetch(dataURL)
      .then(res => res.blob())
      .then(blob => {
        navigator.clipboard.write([new ClipboardItem({'image/png': blob})])
      });
  }

  return (
    <Wrapper>
      <ImageSelectorContainer>
        <div>
          <LeftArrowButton onClick={handleLeftArrowClick} disabled={visibleImageStartIndex === 0}>{'<'}</LeftArrowButton>
        </div>
        {imageUrls.slice(visibleImageStartIndex, visibleImageStartIndex + 4).map((imageUrl, index) => (
          <ImageWrapper key={index} width={100}>
            <GalleryImage
              src={imageUrl}
              alt={`Image ${visibleImageStartIndex + index + 1}`}
              onClick={() => handleImageClick(imageUrl)}
            />
          </ImageWrapper>
        ))}
        <div>
          <RightArrowButton onClick={handleRightArrowClick} disabled={visibleImageStartIndex === imageUrls.length - 4}>{'>'}</RightArrowButton>
        </div>
      </ImageSelectorContainer>
      <MainImageContainer>
        <div id="canvasContainer">
          <Canvas id="canvas" ref={canvasRef} />
        </div>
        <TextBoxContainer>
          <TextBox value={text} onChange={handleTextChange} placeholder="Enter text..." />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <StyledButton font={selectedFont} color="#572E2E" showStroke={showFontOptions} onClick={() => showOptions("Font")}>Font</StyledButton>
<StyledButton font={selectedFont} color="#572E2E" showStroke={showColorOptions} onClick={() => showOptions("Color")}>Color</StyledButton>
<StyledButton font={selectedFont} color="#572E2E" showStroke={showStrokeOptions} onClick={() => showOptions("Stroke")}>Stroke</StyledButton>

          </div>
          {/* Font options */}
          {showFontOptions && (
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px' }}>
    {fonts.map((font, index) => {
      let label;
      switch(index) {
        case 0:
          label = "Doodle";
          break;
        case 1:
          label = "Serious";
          break;
        case 2:
          label = "Lumi";
          break;
        default:
          label = `Font ${index + 1}`;
      }
      return (
        <StyledButton key={index} font={font} color="#ffe0b2" onClick={() => handleFontButtonClick(font)}>
          {label}
        </StyledButton>
      );
    })}
  </div>
)}


          {/* Color options */}
          {showColorOptions && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              {colorsTop.map((color, index) => (
                <StyledButton key={index} font={selectedFont} color={color} onClick={() => handleColorButtonClick(color)} />
              ))}
              {colorsBottom.map((color, index) => (
                <StyledButton key={index + colorsTop.length} font={selectedFont} color={color} onClick={() => handleColorButtonClick(color)} />
              ))}
            </div>
          )}
          {/* Stroke slider */}
          {showStrokeOptions && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
<StrokeSlider value={strokeWidth * 10} onChange={handleStrokeChange} />
              <span>Stroke Width: {strokeWidth}</span>
            </div>
          )}
         <ButtonContainer>
  <AddTextButton onClick={handleAddText}>Add Text</AddTextButton>
  <SaveImageButton onClick={copyToClipboard}>Clipboard</SaveImageButton>
  <SaveImageButton onClick={handleSaveImage}>Save</SaveImageButton>
</ButtonContainer>
        </TextBoxContainer>
      </MainImageContainer>
      <footer />
    </Wrapper>
  );
};

export default MemeMachine;
