import React from 'react';
import styled from 'styled-components';
import { FaFileAlt, FaSave, FaSquare, FaCaretUp, FaCircle, FaShapes, FaFont } from 'react-icons/fa';
import { GiFountainPen, GiFeather, GiPaintBrush } from 'react-icons/gi';

const PropertiesPanel = ({
  propertiesType,
  selectedElement,
  changeTextColor,
  changeFontSize,
  changeFontFamily,
  changeStrokeSize,
  changeStrokeColor,
  changeStrokeDirection,
  changeBackgroundColor,
  changeBrushSize,
  changeBrushColor,
  brushSize,
  brushColor,
  changeCanvasSize,
  fileName,
  setFileName,
  changeShapeType,
  changeShapeOpacity,
  changeShapeColor,
  shapeType,
  shapeOpacity,
  shapeColor,
  createNewProject,
  saveProjectAsPNG,
  setIsEditingPanel, // New
}) => {
  const handleBackgroundColorChange = (e) => {
    changeBackgroundColor(e.target.value);
  };

  const fontOptions = [
    { font: 'Hansip', icon: <FaFont /> },
    { font: 'Comic Sans MS', icon: <GiFountainPen /> },
    { font: 'Arial', icon: <GiFeather /> },
    { font: 'Courier New', icon: <GiPaintBrush /> },
  ];

  return (
    <PanelContainer
      onMouseEnter={() => setIsEditingPanel(true)}
      onMouseLeave={() => setIsEditingPanel(false)}
    >
      <Title>Properties</Title>
      <Label>File Name:</Label>
      <input
        type="text"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
      <Label>Canvas Size:</Label>
      <select onChange={(e) => changeCanvasSize(e.target.value)}>
        <option value="1x1">1x1</option>
        <option value="2x3">2x3</option>
        <option value="1.25x1.5">1.25x1.5</option>
      </select>
      <Label>Background Color:</Label>
      <input type="color" onChange={handleBackgroundColorChange} />
      <ToolButton onClick={createNewProject}>
        <FaFileAlt /> New File
      </ToolButton>
      <ToolButton onClick={saveProjectAsPNG}>
        <FaSave /> Save File
      </ToolButton>
      {propertiesType === 'text' && selectedElement && (
        <>
          <Label>Text Color:</Label>
          <input
            type="color"
            value={selectedElement.fill || '#ffffff'}
            onChange={(e) => changeTextColor(e.target.value)}
          />
          <Label>Font Size:</Label>
          <input
            type="number"
            value={selectedElement.fontSize || 20}
            onChange={(e) => changeFontSize(parseInt(e.target.value, 10))}
          />
          <Label>Font Family:</Label>
          <IconContainer>
            {fontOptions.map(({ font, icon }) => (
              <FontIcon key={font} onClick={() => changeFontFamily(font)}>
                {icon}
              </FontIcon>
            ))}
          </IconContainer>
          <Label>Stroke Size:</Label>
          <input
            type="number"
            value={selectedElement.strokeWidth * 10 || 0}
            onChange={(e) => changeStrokeSize(parseInt(e.target.value, 10))}
          />
          <Label>Stroke Color:</Label>
          <input
            type="color"
            value={selectedElement.stroke || '#000000'}
            onChange={(e) => changeStrokeColor(e.target.value)}
          />
          <Label></Label>
          
        </>
      )}
      {propertiesType === 'paint' && (
        <>
          <Label>Brush Size:</Label>
          <input
            type="range"
            min="1"
            max="50"
            value={brushSize}
            onChange={(e) => changeBrushSize(parseInt(e.target.value, 10))}
          />
          <Label>Brush Color:</Label>
          <input
            type="color"
            value={brushColor}
            onChange={(e) => changeBrushColor(e.target.value)}
          />
        </>
      )}
      {propertiesType === 'shape' && (
        <>
          <Label>Shape Type:</Label>
          <div>
            <ShapeButton onClick={() => changeShapeType('rectangle')}>
              <FaSquare />
            </ShapeButton>
            <ShapeButton onClick={() => changeShapeType('triangle')}>
              <FaCaretUp />
            </ShapeButton>
            <ShapeButton onClick={() => changeShapeType('circle')}>
              <FaCircle />
            </ShapeButton>
            <ShapeButton onClick={() => changeShapeType('hexagon')}>
              <FaShapes />
            </ShapeButton>
          </div>
          <Label>Shape Color:</Label>
          <input
            type="color"
            value={shapeColor}
            onChange={(e) => changeShapeColor(e.target.value)}
          />
          <Label>Shape Opacity:</Label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={shapeOpacity}
            onChange={(e) => changeShapeOpacity(parseFloat(e.target.value))}
          />
        </>
      )}
    </PanelContainer>
  );
};

export default PropertiesPanel;

const PanelContainer = styled.div`
  background: #1e1e1e;
  padding: 10px;
  color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  width: 200px;
`;

const Title = styled.h3`
  margin: 0 0 10px;
  font-size: 1.2em;
`;

const Label = styled.label`
  margin: 10px 0 5px;
  font-size: 0.9em;
`;

const ToolButton = styled.button`
  background: #444;
  color: #fff;
  border: none;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background: #555;
  }

  svg {
    margin-right: 5px;
  }
`;

const ShapeButton = styled.button`
  background: #444;
  color: #fff;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #555;
  }

  svg {
    font-size: 1.5em;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const FontIcon = styled.div`
  cursor: pointer;
  font-size: 1.5em;
  padding: 5px;

  &:hover {
    background: #333;
  }
`;
