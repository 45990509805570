import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImageTray from '../components/ImageTray';
import DashboardNavbar from '../components/Dashboard_Navbar';

const AppContainer = styled.div`
  padding-top: 100px;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px; /* Add left padding for mobile */
  padding-right: 20px; /* Add right padding for mobile */
`;

const CategoryFilterContainer = styled.div`
  width: 40%;
  font-family: 'lumispeak', sans-serif;
  padding: 20px; /* Increased top padding */
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%; /* Adjust width for mobile devices */
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const CategoryText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${({ isSelected }) => (isSelected ? '#572E2E' : '#572E2E')}; /* Changed color for unselected state */
  background-color: ${({ isSelected }) => (isSelected ? '#fabf09' : 'transparent')};
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
`;

const ImageGallery = styled.div`
  width: 50%;
  max-width: 800px; /* Limit the width of the images */
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%; /* Adjust width for mobile devices */
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Add some spacing */
`;

const Button = styled.button`
  font-size: 16px;
  padding: 8px 16px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #572E2E;
  color: #fff;
`;

export default function MemeFolder() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [imagetray, setImagetray] = useState(null);

  const categories = ['GM', 'BULL', 'BEAR', 'GF'];

  function getimages(cat) {
    setSelectedCategory(cat);
    setImagetray(<ImageTray folder="memefolder" dir={cat} />);
  }

  useEffect(() => {
    // Preload the "GF" category when the component mounts
    getimages('GF');
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  function handlePrevious() {
    const currentIndex = categories.indexOf(selectedCategory);
    if (currentIndex > 0) {
      const previousCategory = categories[currentIndex - 1];
      getimages(previousCategory);
    }
  }

  function handleNext() {
    const currentIndex = categories.indexOf(selectedCategory);
    if (currentIndex < categories.length - 1) {
      const nextCategory = categories[currentIndex + 1];
      getimages(nextCategory);
    }
  }

  return (
    <AppContainer>
      <DashboardNavbar />
      <CategoryFilterContainer>
        <CategoryWrapper>
          {categories.map((category, index) => (
            <CategoryText
              key={index}
              isSelected={selectedCategory === category}
              onClick={() => getimages(category)}
            >
              {category}
            </CategoryText>
          ))}
        </CategoryWrapper>
      </CategoryFilterContainer>
      <ImageGallery>{imagetray}</ImageGallery>
    </AppContainer>
  );
}
