import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { SizedBox } from "./SizedBox";
import { BrowserView, MobileView } from "react-device-detect";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  width: 90%;
  aspect-ratio: 1;
  border: 2px solid black;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px; // Adjusting for uniform spacing
  }
`;

const Icon = styled.img`
  width: 25%;
  margin: 5px;
  margin-bottom: 25px;
  border-radius: 20px;
`

const Heading = styled.h1`
  margin: 15px;
  font-family: lumispeak;
  color: #572E2E;
`;

const SubTitle = styled.h2`
  font-family: lumispeak;
  color: #572E2E;
`;

const SubText = styled.h5`
  margin-x: 5%;
`;

const GridItem = ({ iconSrc, title, text }) => (
  <Container>
    <Icon src={iconSrc} />
    <BrowserView><SubTitle>{title}</SubTitle> <SubText>{text}</SubText></BrowserView>
    <MobileView><h3>{title}</h3> <h5>{text}</h5></MobileView>
    
    <SizedBox height={"25%"} />
</Container>
);

const PsyopGrid = () => {
return (
<>
<BrowserView>
<div style={{ textAlign: "center" }}>
<Heading>What makes Lumi special</Heading>
<SubText>With such a small collection and no expectations, we build with the community by our side. Look at some of the things we are building.</SubText>
<SizedBox height={"70px"} />
<Row>
<Col>
<GridItem iconSrc={"/Icons/meme.png"} title="MEME GENERATOR" text="Meme generator generates meme from a generator that generates memes." />
</Col>
<Col>
<GridItem iconSrc={"/Icons/folder.png"} title="MEME FOLDER" text="A folder that stores memes, a meme folder if you will. One that stores some memes" />
</Col>
<Col>
<GridItem iconSrc={"/Icons/pin.png"} title="JOB BOARD" text="Help us build cool shit, get a Lumi." />
</Col>
</Row>
<SizedBox height={"100px"} />
<Row>
<Col>
<GridItem iconSrc={"/Icons/hammer.png"} title="LUMI BUILDER" text="Build your own Lumi, and explore the traits of the collection while you do!" />
</Col>
<Col>
<GridItem iconSrc={"/Icons/spatula.png"} title="COMMUNITY KITCHEN" text="Bro we cookin, come in the kitchen and cook with us" />
</Col>
<Col>
<GridItem iconSrc={"/Icons/controller.png"} title="GAMES" text="Play games starring everyone's favorite psyop- LUMI!" />
</Col>
</Row>
</div>
</BrowserView>
<MobileView>
<div style={{ textAlign: "center" }}>
<Heading>What makes Lumi special</Heading>
<SubText>With such a small collection and no expectations, we build with the community by our side. Look at some of the things we are building.</SubText>
<SizedBox height={"70px"} />
<Row>
<Col>
<GridItem iconSrc={"/Icons/meme.png"} title="MEME GENERATOR" text="Meme generator generates meme from a generator that generates memes. The meme generator" />
</Col>
</Row>
<Row>
<Col>
<GridItem iconSrc={"/Icons/folder.png"} title="MEME FOLDER" text="A folder that stores memes, a meme folder if you will. One that stores some memes" />
</Col>
</Row>
<Row>
<Col>
<GridItem iconSrc={"/Icons/pin.png"} title="JOB BOARD" text="Help us build cool shit, get a Lumi." />
</Col>
</Row>
<Row>
<Col>
<GridItem iconSrc={"/Icons/hammer.png"} title="LUMI BUILDER" text="Build your own Lumi, and explore the traits of the collection while you do!" />
</Col>
</Row>
<Row>
<Col>
<GridItem iconSrc={"/Icons/spatula.png"} title="COMMUNITY KITCHEN" text="Bro we cookin, come in the kitchen and cook with us" />
</Col>
</Row>
<Row>
<Col>
<GridItem iconSrc={"/Icons/controller.png"} title="GAMES" text="Play games starring everyone's favorite psyop- LUMI!" />
</Col>
</Row>
</div>
</MobileView>
</>
)
}

export default PsyopGrid;