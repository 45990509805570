import React, { useState } from 'react';
import styled from 'styled-components';

const FAQContainer = styled.div`
  margin: 20px;
  text-align: center;
`;

const Heading = styled.h1
`
margin: 30px;
font-family: lumispeak;
color: #572E2E;
`;

const Question = styled.div`
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #572E2E;
  border-top: 1px solid #572E2E;
  color: #572E2E;
  font-size: 1.7em;
  font-weight: bold;
  text-align: left;
  font-family: lumispeak;
`;

const Answer = styled.div`
  padding: 10px;
  display: none;
  text-align: left;
  font-size: 1.4em;
  &.open {
    display: block;
  }
`;

const SubTitle = styled.h4
`
padding-bottom: 30px;
color: #572E2E;
`;

const faqs = [
  {
    question: 'What is Lumi?',
    answer: 'Lumi is a collection of 404 characters, 5 diffrent personalities. Lumi is nothing, and everything is Lumi.'
  },
  {
    question: 'How many Lumis are there?',
    answer: 'Only 404 will ever exist and there is 8 billion people'
  },
  {
    question: 'Who built Lumi?',
    answer: 'Lumi was built by Paradox, what is Paradox?'
  },
  {
    question: 'ILUMInati? Who\'s that?',
    answer: 'Don\'t ask questions that you are not prepared to know the answer to'
  },
  {
    question: 'What is Lumi\'s end goal?',
    answer: 'Curate a holder base that is based'
  },
  {
    question: 'What are the benefits of holding a Lumi?',
    answer: 'nothing, we will never have a roadmap or whatever, get fucked'
  },
];

const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = index => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <FAQContainer>
    <Heading>Frequently Asked Questions</Heading>
    <SubTitle>We believe transparency is key</SubTitle>
      {faqs.map((faq, index) => (
        <div key={index}>
          <Question onClick={() => toggleFAQ(index)}>
            {faq.question}
          </Question>
          <Answer className={openFAQ === index ? 'open' : ''}>
            {faq.answer}
          </Answer>
        </div>
      ))}
    </FAQContainer>
  );
};

export default FAQ;
